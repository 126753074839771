<template>
  <div class="grid-container">
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-10">
        <h1 class="pb-5">Cost Domains</h1>
      </div>
      <div class="cell small-8 medium-2">
        <router-link
          to="/admin/cost-domains/create">
          <button class="button expanded">
            Add Cost Domain
          </button>
        </router-link>
      </div>
    </div>
    <div class="grid-x grid-padding-x pt-5">
      <div class="cell small-12">
        <table>
          <thead>
            <tr>
              <th>Domain</th>
              <th>Type</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(domain, index) in costDomains"
              :key="`jt_${index}`">
              <td>{{ domain.domain }}</td>
              <td>
                <span v-if="domain.fee === true">Fee</span>
                <span v-else>Expense</span>
              </td>
              <td class="text-right">
                <span
                  class="material-symbols-outlined icon-yellow icon-link"
                  @click="domainInEdit = index, selectedDomain = domain"
                  >edit</span>
                &nbsp;
                <span
                  class="material-symbols-outlined icon-red icon-link"
                  @click="deleteDomain(index)"
                  >delete</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-if="domainInEdit > -1" class="dialogue">
      <label>Domain</label>
      <input type="text" v-model="selectedDomain.domain" />
      <label>Fee/Expense</label>
      <select v-model="selectedDomain.fee">
        <option value="true">Fee</option>
        <option value="false">Expense</option>
      </select>
      <button
        @click="saveUpdate"
        class="button small">Save</button>
      <button
        @click="domainInEdit = -1, selectedDomain = {}"
        class="button small float-right">Cancel</button>
    </div>
  </div>
</template>

<script>
import axios from '../../../axios';

export default {
  name: 'CostDomainsIndex',
  data() {
    return {
      costDomains: [],
      domainInEdit: -1,
      selectedDomain: {},
    };
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  methods: {
    deleteDomain(index) {
      const costDomainId = this.costDomains[index].id;
      axios.get(`/costDomains/delete/${costDomainId}.json?token=${this.token}`)
        .then(() => {
          this.costDomains.splice(index, 1);
        })
        .catch(() => {
          this.failed = true;
        });
    },
    saveUpdate() {
      const postData = {};
      postData.cost_domain = this.selectedDomain;
      axios.post(`/costDomains/update/${this.selectedDomain.id}.json?token=${this.token}`, postData)
        .then((response) => {
          this.costDomains[this.domainInEdit] = response.data.domain;
          this.selectedDomain = {};
          this.domainInEdit = -1;
        })
        .catch(() => {
          this.failed = true;
        });
    },
    fetchCostDomains() {
      axios.get(`/costDomains/getAll.json?token=${this.token}`)
        .then((response) => {
          this.costDomains = response.data.domains;
        })
        .catch(() => {
          this.failed = true;
        });
    },
  },
  mounted() {
    setTimeout(() => {
      this.fetchCostDomains();
    }, 100);
  },
};
</script>
